import { AppConfig } from "./interfaces/app-config";

export const globalConfig: AppConfig = {
    pages: {
        login: {
            title: 'Iniciar sesión',
            subtitle: 'Introduzca su correo electrónico y contraseña para iniciar sesión',
            form: {
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                rememberMe: {
                    label: 'Recordarme'
                },
            },
            socialAuth: 'O inicia sesión con',
            forgotPassword: '¿Ha olvidado su contraseña?',
            submit: 'Iniciar sesión',
            nextAuth: {
                question: '¿No tienes cuenta?',
                action: 'Registrate aquí'
            }
        },
        register: {
            title: 'Registrate',
            subtitle: 'Introduzca sus datos personales para crear una cuenta',
            form: {
                name: {
                    label: 'Nombres y Apellidos',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                terms: {
                    label: 'Acepto los ',
                    action: 'términos y condiciones'
                }
            },
            socialAuth: 'O registrate con',
            submit: 'Registrarse',
            nextAuth: {
                question: '¿Ya tienes una cuenta?',
                action: 'Inicia sesión'
            }
        },
        account: {
            title: 'Mi Cuenta'
        },
        
        users: {
            titlePlural: 'Usuarios',
            titleSingular: 'Usuario',
            form: {
                name: {
                    label: 'Nombres y Apellidos',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                image: {
                    label: 'Imagen',
                    placeholder: 'Selecciona una imagen'
                }
            }
        },
        customer: {
            titlePlural: 'Registrados',
            titleSingular: 'Registro',
            form: {
                name: {
                    label: 'Nombres y Apellidos',
                    placeholder: 'Ingresa tu nombre completo'
                },
                email: {
                    label: 'Correo electrónico',
                    placeholder: 'Ingresa tu correo electrónico'
                },
                password: {
                    label: 'Contraseña',
                    placeholder: 'Ingresa tu contraseña'
                },
                image: {
                    label: 'Imagen',
                    placeholder: 'Selecciona una imagen'
                }
            }
        },
        profile: {
            titlePlural: 'Perfiles',
            titleSingular: 'Perfil',
            form:{},
            form_filter: {
                search: {
                    label: 'Buscar',
                    placeholder: 'Escribir...'
                },
                name:{
                    label: 'Nombre',
                    placeholder: 'Escribir...'
                },
                lastName:{
                    label: 'Apellido',
                    placeholder: 'Escribir...'
                },
                email:{
                    label: 'Correo electrónico',
                    placeholder: 'Escribir...'
                },
                numberCi:{
                    label: 'Numero de carnet',
                    placeholder: 'Escribir...'
                },
                initial_date:{
                    label: 'Fecha inicio',
                    placeholder: 'Escribir...'
                },
                end_date:{
                    label: 'Fecha fin',
                    placeholder: 'Escribir...'
                },
            }
        },
        historyTickets: {
            titlePlural: 'Historial',
            titleSingular: 'Historial',
            form:{},
            form_filter: {
                search: {
                    label: 'Buscar código',
                    placeholder: 'Escribir...'
                },
                name:{
                    label: 'Nombre',
                    placeholder: 'Escribir...'
                },
                lastName:{
                    label: 'Apellido',
                    placeholder: 'Escribir...'
                },
                email:{
                    label: 'Correo electrónico',
                    placeholder: 'Escribir...'
                },
                numberCi:{
                    label: 'Numero de carnet',
                    placeholder: 'Escribir...'
                },
                initial_date:{
                    label: 'Fecha inicio',
                    placeholder: 'Escribir...'
                },
                end_date:{
                    label: 'Fecha fin',
                    placeholder: 'Escribir...'
                },
                event:{
                    label: 'Eventos',
                    placeholder: 'Seleccionar...'
                },
            }
        },

    },
    validations: {
        required: "El campo \"{label}\" es requerido.",
        number: "El campo \"{label}\" debe contener solo números.",
        minlength: "El campo \"{label}\" debe tener al menos \"{requiredLength}\" caracteres.",
        maxlength: "El campo \"{label}\" debe tener un máximo de \"{requiredLength}\" caracteres.",
        min: "El campo \"{label}\" debe contener al menos \"{min}\" elementos.",
        max: "El campo \"{label}\" debe tener un máximo de \"{max}\" elementos.",
        email: "El campo \"{label}\" debe ser un email válido.",
        default: "El campo \"{label}\" es inválido.",
    },
    menu: [
        {
            headTitle1: "General",
        },
        {
            id: 1,
            level: 1,
            path: '/account',
            title: "Mi Cuenta",
            icon: "home",
            type: "link", //opciones:link, extTabLink, link-external(para abrir admin externos)
            permissions: 'default', // default(se usa para pasar la validacion de permisos), otros nombres necesita estar q este en el servidor
        },
        {
            headTitle1: "Admin",
        },
        {
            id: 2,
            level: 1,
            path: '/users',
            title: "Usuarios del admin",
            icon: "bookmark",
            type: "link",
        },
        {
            id: 3,
            level: 1,
            path: '/profile',
            title: "Registrados",
            icon: "bookmark",
            type: "link",
        },
        //{
        //    id: 2,
        //    level: 1,
        //    path: "/predios",
        //    title: "Predios",
        //    icon: "user",
        //    type: "link",
        //},
    ],
    horizontalMenu: [
        // {
        //     id: 1,
        //     level: 1,
        //     path: '/account',
        //     title: "Mi Cuenta",
        //     icon: "home",
        //     type: "link",
        // },
    ],
};