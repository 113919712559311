import { Injectable } from '@angular/core';
import { EncryptionService } from './encryption.service';
import { globalConfig } from '../global-config';
import { AppConfig } from '../interfaces/app-config';

@Injectable({
  providedIn: 'root'
})
export class ProjectConfigService {
  private config: AppConfig;

  constructor(
    private encryptionService: EncryptionService,
  ) {}

  private deepMerge(target: any, source: any): any {
    if (!target || typeof target !== 'object') {
      target = {};
    }
  
    for (const key of Object.keys(source)) {
      if (Array.isArray(source[key])) {
        // Reemplaza completamente los arrays
        target[key] = source[key];
      } else if (source[key] instanceof Object) {
        // Fusiona objetos recursivamente
        target[key] = this.deepMerge(target[key], source[key]);
      } else {
        // Asigna valores primitivos
        target[key] = source[key];
      }
    }
  
    return target;
  }

  loadConfig(): Promise<any> {
    const globalConfig$ = Promise.resolve(globalConfig);
    // console.log(window.selfPc);
    let _x = '12345678901234567890123456789012', _y = '1234567890123456';
    let selfPc = this.encryptionService.decrypt(window.selfPc, _x, _y);
    // console.log(selfPc);
    const projectConfig$ = Promise.resolve(selfPc);


    return Promise.all([globalConfig$, projectConfig$]).then(([globalConfig, projectConfig]) => {
        console.log(this.deepMerge(globalConfig, projectConfig || {}));
      this.config = this.deepMerge(globalConfig, projectConfig || {});
    });
  }

  getConfig(): AppConfig {
    return this.config;
  }
}